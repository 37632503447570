#accountHolder {
  position: relative;
}

#accountHolder .pb-Icon__iconWrapper {
  position: absolute;
  top: 12px;
  right: 9px;
}

#accountHolder .pb-Icon__Icon {
  fill: #ccc;
}

@media screen and (max-width: 600px) {
  .validateButton #findAddressButton {
    margin-left: 10px;
  }
  #BankDetailsErrors {
    margin-left: -10px;
  }
}

#directDebitIcon {
  display: block;
  width: 133px; 
  height: 60px;
}