.pb-ProgressIndicator__indicator {
  background-color: #4d8210 !important;
}

.pb-Footer__Footer {
  text-align: left;
}

.font-regular {
  font-weight: normal !important;
}
