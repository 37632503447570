.toolContainer {
  margin-bottom: 10px;
  border-right: 1px solid #a4be94;
  /* border-radius: 8px; */
  background-color: #edf4e9;
  padding: 5px;
  text-align: center;

  visibility: visible;
  transition: visibility 5s 5s;
  left: -260px;
  transition: all linear;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 3;
}

.toolContainer--open {
}

.toolContainer--closed {
  left: -260px;
}

.toolContainer button {
  border: 0;
  background-color: #a4be94;
  margin: 1px 0;
  padding: 6px 10px;
  width: 100%;
}

.toolContainer button:hover {
  border-color: #cecccc;
  background: #cecccc;
}

.vert-debug label {
  font-weight: bold;
  font-family: "M&SLondon", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #ffffff;
  padding: 8px 0;
  background-color: #a4be94;
  border-radius: 4px;
  display: block;
}

.vert-debug input[type="checkbox"] {
  margin: 0px 4px;
}

#nav-icon {
  width: 60px;
  height: 45px;
  position: relative;
  margin: 15px auto;
  transform: rotate(0deg);

  transition: 0.5s ease-in-out;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 15px;
}
/* Icon 2 */

#nav-icon {
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: #a4be94;
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon span:nth-child(1),
#nav-icon span:nth-child(2) {
  top: 0px;
}

#nav-icon span:nth-child(3),
#nav-icon span:nth-child(4) {
  top: 18px;
}

#nav-icon span:nth-child(5),
#nav-icon span:nth-child(6) {
  top: 36px;
}

#nav-icon.open span:nth-child(1),
#nav-icon.open span:nth-child(6) {
  transform: rotate(45deg);
}

#nav-icon.open span:nth-child(2),
#nav-icon.open span:nth-child(5) {
  transform: rotate(-45deg);
}

#nav-icon.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

#nav-icon.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}

#root.navtools {
  left: 0;
  position: absolute;
  width: 100%;
  transition: left 0.4s ease-out;
}

#root.open {
  position: absolute;
  left: 260px;
  transition: left 0.4s ease-out;
}

.button--next {
  width: 48%;
  float: right;
}

.button--previous {
  width: 48%;
  float: left;
}
