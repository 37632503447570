.heading {
  display: flex;
  align-items: baseline;
}
.heading--header {
  flex: 1 1 auto;
}
.heading--editlink {
  flex: 0 0 auto;
}
