table {
  border-collapse: collapse;
  margin-bottom: 20px;
  width: 100%;
}
th,
td {
  border: 1px solid #000;
  text-align: left;
  padding: 4px;
  vertical-align: top;
}

/* Unordered lists inside table cells - fixing IE bug */
.table-list li {
  display: block;
}
.table-list li span:nth-child(1) {
  display: block;
  float: left;
}
.table-list li span:nth-child(2) {
  display: block;
  margin-left: 20px;
}

/* 
* Table cells that do not have borders:
* used when visually continuing the text from one cell into another
*/
th.noBottomBorder,
td.noBottomBorder {
  border-bottom: 0;
}
th.noTopBorder,
td.noTopBorder {
  border-top: 0;
}
.breakWord {
  word-break: break-word;
}

.child-list {
  list-style: none;
  margin-left: 30px;
}

/* Table - remove margin bottom on last paragraph in cell */
.noMarginBottomLastP tr th p:last-child,
.noMarginBottomLastP tr td p:last-child {
  margin-bottom: 0;
}
.noMarginBottomLastP tr th p:last-child::after,
.noMarginBottomLastP tr td p:last-child::after {
  height: 0;
  content: "";
}

/* Table column widths */
/* Two columns */
.twoColumnTable tr td:nth-child(1) {
  width: 40% !important;
}
.twoColumnTable tr td:nth-child(2) {
  width: 60% !important;
}

/* Three columns */
.threeColumnTable tr td:nth-child(1),
.threeColumnTable tr td:nth-child(2) {
  width: 33% !important;
}
.threeColumnTable tr td:nth-child(3) {
  width: 34% !important;
}

/* Four columns */
.fourColumnTable tr td {
  width: 25% !important;
}

#no-space {
  margin-bottom: 0px !important;
}
.with-indentation {
  margin-left: 28px !important;
}

.box-signature {
  border: 1px solid black;
  margin-bottom: 15px;
  padding: 10px;
}
.custom-ordered-list li .pb-ListItem__listStyle::before {
  text-transform: uppercase;
}

.insetContent {
  margin-top: var(--unit3);
  margin-left: 1.5em;
}
