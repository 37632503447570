.acceptMasthead {
  background-color: #edf4e9;
  overflow: auto;
  padding: 16px 16px 15% 16px;
  margin-bottom: 24px;
}
@media screen and (min-width: 500px) {
  .acceptMasthead {
    padding-bottom: 40px;
  }
}
@media screen and (min-width: 1056px) {
  .acceptMasthead {
    padding-bottom: 16px;
    margin-bottom: 48px;
  }
}

.acceptMasthead__inner {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 10px;
}

.acceptMasthead__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.acceptMasthead__description h3 {
  font-weight: 400;
}

@media screen and (min-width: 500px) {
  .acceptMasthead__inner {
    margin-bottom: 30px;
  }
}

#headingMasthead {
  font-size: var(--fontsize4) !important;
}
