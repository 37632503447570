@media screen and (min-width: 600px) {
  #findAddressSection {
    display: flex;
  }
  .findAddressButton {
    margin-left: 20px;
  }
  .findAddressSpinner {
    width: 100%;
    position: absolute;
    margin-left: 24px;
  }
  .findAddressSpinner p {
    line-height: 0.6;
    margin-left: 35px;
  }
}

@media screen and (max-width: 600px) {
  input[id*="post-code-field"] {
    margin-bottom: 16px;
  }
  .findAddressSpinner {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .findAddressSpinner p {
    line-height: 0.6;
    margin-left: 10px;
  }
}

.findAddressButton button {
  line-height: 1;
  font-weight: bold;
}

.spinnerHidden {
  display: none;
}

.findAddressSpinner .pb-Spinner__Spinner {
  float: left;
  width: 27px;
  height: 27px;
  margin-top: 9px;
}
