/* PostCode media query width*/

@media screen and (min-width: 600px) {
  input[id*="post-code-field"] {
    width: 190px;
  }
}

@media screen and (min-width: 600px) {
  #house-name-field {
    width: 290px;
  }
}
