.cardMastHead {
  background-color: #edf4e9;
  overflow: hidden;
  padding: 16px;
  margin-bottom: 24px;
}

.cardMastHead__inner {
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.cardMastHead__image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 16px;
}

.cardMastHead__image img {
  height: 56px;
}

.cardMastHead__description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cardMastHead__description h3 {
  font-weight: 400;
}

@media screen and (min-width: 600px) {
  .cardMastHead__image img {
    height: 72px;
  }
  .cardMastHead__image {
    margin-right: 24px;
  }

  .cardMastHead {
    margin-bottom: 32px;
  }
}
