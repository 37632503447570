/* header to card ino */
.label {
  font-weight: bold;
}

/* two col layout margin and padding */
#cell-1 p:last-child {
  margin-bottom: 16px;
}

@media screen and (min-width: 1056px) {
  #cell-1 p:last-child {
    margin-bottom: 0px;
  }
}

#cell-2 p:last-child {
  margin-bottom: 0px;
}

/* card image container */
@media screen and (max-width: 1055px) {
  .acceptMasthead__image > div {
    margin: 0 0 24px 0;
  }
}

/* Card image */
.acceptMasthead__image img {
  display: flex;
  margin-top: -76px;
}

@media screen and (min-width: 1056px) {
  .acceptMasthead__image img {
    position: absolute;
    width: 100%;
    margin-top: -48%;
  }
}
