#skip .pb-Button__Button--primary {
  /* skip button navigation to look secondary */
  background: white;
  border: 2px solid var(--green-leaf);
  color: var(--green-leaf);
  margin-top: 30px;
}

#diva-app {
  /* ensures progress bar is below camera */
  z-index: 20;
  position: relative;
}

.hidden {
  display: none;
}
